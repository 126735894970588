import React from "react"
import { Seo } from "../../components/seo"
import { Link } from "gatsby"
import JobFormEs from "../../components/forms/JobFormEs"

const JobFormPageEs = () => {
  return (
    <>
      <Seo title={`Formulario de trabajos en Mexico`} language={"es"} />
      <section className="container english-page-body">
        <div className="row row-en">
          <div className="en-pages">
            <h1>Formulario de Consulta Gratuito</h1>
            <div className="consultation-form">
              <p className="text">
                Nuestra empresa se esfuerza por satisfacer las preferencias de
                nuestros solicitantes en la medida de lo posible. Podemos
                negociar las condiciones de empleo con las empresas contratantes
                en su nombre si es necesario. Tenga en cuenta que es posible que
                no se atiendan algunas solicitudes. Para obtener más información
                sobre nuestro apoyo, consulte el “
                <span>
                  <Link to="/es/process">
                    Proceso de introducción al trabajo
                  </Link>
                </span>
                ”.
              </p>
            </div>
            <JobFormEs />
          </div>
        </div>
      </section>
    </>
  )
}

export default JobFormPageEs
